<template>
    <div>
        <v-container fluid>
            <v-data-iterator :items="items" item-key="_id" :loading="loading" loading-text="加载中..."
				:options.sync="options" :server-items-length="totalItems"
				:items-per-page="12" :footer-props="{'items-per-page-options':[8,12,16]}">
            <template v-slot:header>
                <v-toolbar dense class="mb-1">
                    <v-select class="flex-grow-0"
                        v-model="groupBy"
                        dense
                        hide-details
                        :items="groupKeys"
                        label="过滤"
						@change="fetchData"
                    ></v-select>
                    <v-select class="flex-grow-0 ml-4"
						v-if="consultants.length"
                        v-model="consultant"
                        dense
                        hide-details
                        :items="consultants"
                        label="健康顾问"
						item-text="name"
						item-value="_id"
						@change="fetchData"
                    ></v-select>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
            <v-row dense>
                <v-col v-for="item in items" :key="item._id" xs="12" md="4" lg="3">
                    <v-card>
                        <v-toolbar :color="getTitleColor(item)" dark dense>
                			<v-toolbar-title>{{item.title}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-icon v-if="item.alert">alarm</v-icon>
                            <v-btn icon @click.stop="remove(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-list id="note">
                                <v-list-item>
                                    <v-list-item-title>任务岗位</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{item.targetRole}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item v-if="item.scheduleTime">
                                    <v-list-item-title>约定时间</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{formatTime(item.scheduleTime)}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item v-else>
                                    <v-list-item-title>发生时间</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{formatTime(item.initialTime)}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>超时时间</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{formatTime(item.expiredTime)}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>后续任务</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{item.prompt}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>健康顾问</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{item.consultantName}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>所选诊所</v-list-item-title>
                                    <v-list-item-subtitle class="text-right">{{item.region}}</v-list-item-subtitle>
                                </v-list-item>
                                <v-list-group no-action @click="getCustomer(item)">
                                    <template v-slot:activator>
                                        <v-list-item-title>客户信息</v-list-item-title>
                                    </template>
                                    <template v-if="item.customer">
                                    <v-list-item>
                                        <v-list-item-title>微信名</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{item.customer.userInfo.nickName}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>姓名</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{item.customer.pii.username}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>证件类型</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{item.customer.pii.idtype}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>证件号</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{getMaskedIdcard(item.customer)}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>性别</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{item.customer.pii.gender}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>年龄</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{getAge(item.customer)}}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>电话</v-list-item-title>
                                        <v-list-item-subtitle class="text-right">{{item.customer.pii.phone}}</v-list-item-subtitle>
                                    </v-list-item>
                                    </template>
                                </v-list-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            </template>
            </v-data-iterator>
        </v-container>
    </div>
</template>

<script>
	import {formatTime, optstr, calculateAge, nowOffsetHours} from '../utils'

    export default {
        name: 'Note',
        data() {
            return {
                items: [],
                loading: false,
                groupBy: '全部',
                groupKeys: ['全部','超时','待办'],
				options: {},
				totalItems: 0,
				consultant: '',
				consultants: []
            }
        },
        mounted() {
			this.formatTime = formatTime;
			this.fetchConsultants();
			this.fetchData();
        },
        methods: {
			async fetchConsultants() {
				if (this.$hasPrivilege('查看全部任务')) {
					const db = this.$tcbapp.database();
					const _ = db.command;
					const res = await db.collection('consultants').where({rank:_.gt(0)}).field({_id:true,name:true}).get();
					this.consultants = [{name:'全部',_id:''}].concat(res.data);
				}
			},
            async fetchData() {
                const db = this.$tcbapp.database();
                const _ = db.command;
				let filter;
				const u = this.$getCurrentUser();
				if (this.$hasPrivilege('查看全部任务')) {
					if (this.consultant) {
						filter = {'consultantId':this.consultant, targetRole:'健康顾问'};
					} else {
						filter = {};
					}
				} else if (this.$hasPrivilege('健康顾问')) {
					filter = {'consultantId':u.consultantId, targetRole:'健康顾问'};
				} else {
                    filter = {targetRole: _.in(u.role), region: _.in(u.region)};
                }
				if (this.groupBy === '超时') {
					filter.expiredTime = _.lt(new Date())
				} else if (this.groupBy === '待办') {
					filter.expiredTime = _.gte(new Date())
				}
                filter.closed = _.neq(true);
				this.loading = true;
                try {
                    const countRes = await db.collection('wp2note').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
                    const res = await db.collection('wp2note').where(filter).orderBy('expiredTime','asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
                    this.items = res.data;
                } catch(err) {
                    console.error(err);
                }
				this.loading = false;
            },
            getCustomer(item) {
                if (item.customer) return;
                const db = this.$tcbapp.database();
                db.collection('wp2order').doc(item.orderId).get().then(res => {
                    if (res.data && (res.data.length > 0)) {
                        item.customer = res.data[0];
                        this.$forceUpdate();
                    }
                });
            },
			getTitleColor(item) {
				const now = new Date(Date.now() + nowOffsetHours * 3600 * 1000);
				if (now > item.timeoutTime) {
					return 'red'
				} else if (now > item.expiredTime) {
					return 'amber'
				} else if (now > item.remindTime) {
					return 'cyan lighten-1'
				} else {
					return 'blue'
				}
			},
			getMaskedIdcard(item) {
				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getAge(item) {
				if (!item.QA) return '';
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOpts(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
            async remove(item) {
				const res = await this.$dialog.confirm({
					text: '确定要删除此条目？',
					title: '提示'
				});
				if (!res) return;
                const db = this.$tcbapp.database();
                db.collection('wp2note').doc(item._id).update({closed:true}).then(res => {
                    this.fetchData();
                }).catch(console.error);
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			}
		}
    }
</script>

<style>
#note .v-list-item {
    min-height:32px;
}
#note .v-list-item__title {
    flex:none;
    margin-right:6px;
}
</style>